const services = [
    {
        heading: "Ring Ceremony Pacakage",
        imgLink: "https://akm-img-a-in.tosshub.com/indiatoday/images/story/201911/Capture1.png?size=690:388",
        subh1: "For One Day",
        subh2: "Deliverables Items",
        subh3: "Booking Details ( Total Amount 35,000 )",
        list1: ["Traditional Video", "Traditional Photography", "Candid Photography", "Cinematic Videography", "Light and Umbrella Light ( As Per Requirement )"],
        list2: ["We will provide total soft data in the client drive ( after complting the editing work ) ", " Photo Book (180 Photo, 30 Page in the Album)", "Final Edited video in pendrive", "Teaser -1 to 2 min", " Intro 30 sec ( coming soon video )", "Reel – 1", "Gift pack mini book ( small album) , Save the date video and image"],
        list3: ["Booking amount – Rs. 5k", "50% Advance Payment Before Work", "25% After Program", "25% With deliverables Item"],
    },
    {
        heading: "Wedding Photography Package",
        imgLink: "https://media-api.xogrp.com/images/f4570601-a1e6-4802-86fb-ad9e988066fe~rs_768.h",
        subh1: "For One Day",
        subh2: "Deliverables Items",
        subh3: "Booking Details ( Total Amount 55,000 )",
        list1: ["Traditional Video", "Traditional Photography", "Candid Photography", "Cinematic Videography", "Drone", "Light and Umbrella Light ( As Per Requirement )"],
        list2: ["We will provide total soft data in the client drive ( after complting the editing work ) ", " Photo Book (300 Photo, 50 Page in the Premium Album)", "Final Edited video in pendrive", "Teaser -1 to 2 min", " Intro 30 sec ( coming soon video )", "Reel – 2", "Gift pack mini book ( small album) , Save the date video and image"],
        list3: ["Booking amount – Rs. 10k", "50% Advance Payment Before Work", "25% After Program", "25% With deliverables Item"],
    },
    {
        heading: "Birthday / Ring Ceremony / Recception Package",
        imgLink: "https://dtz3um9jw7ngl.cloudfront.net/blog/content/how-to-take-good-pictures-for-social-media/GaryMorrisroe-Lifestyle-00578.jpg",
        subh1: "For One Day",
        subh2: "Deliverables Items",
        subh3: "Booking Details ( Total Amount 20,000 )",
        list1: ["Traditional Video", "Traditional Photography", "Light and Umbrella Light ( As Per Requirement )"],
        list2: ["We will provide total soft data in the client drive ( after complting the editing work ) ", " Photo Book (180 Photo, 30 Page in the Premium Album)", "Final Edited video in pendrive", "Teaser -1 to 2 min", " Intro 30 sec ( coming soon video )", "Gift pack mini book ( small album) , Save the date video and image"],
        list3: ["Booking amount – Rs. 5k", "50% Advance Payment Before Work", "25% After Program", "25% With deliverables Item"],
    },
    {
        heading: "Wedding Photography Package",
        imgLink: "https://www.haringphotography.com/wp-content/uploads/2019/10/best-miami-wedding-photographers-12-1800x1200.jpg",
        subh1: "For Three Day",
        subh2: "Deliverables Items",
        subh3: "Booking Details ( Total Amount 1,20,000 )",
        subh4: "1. Cinematic & Candid Package",
        subh5: "(1st Day & 2nd Day)",
        subh6: "2. Next Day Till Vidai",
        list1: [" Cinematic Camera", "Cinematic Camera With Gimbal", "Traditional Photography", "Candid Photography", "Studio Light (As Per requiremant)"],
        list2: ["We will provide total soft data in the client drive ( after complting the editing work ) ", "Album 400 Photo, 40 + 40 Page Album (Extra Rs-500/- Per Page)", "Final Edited Wedding Video in PenDrive x 2 (3.0) (60 to 75 Minutes Approx)", "Teaser - 30 Sec (Coming Soon Video)", "Reel - 2", "Gift pack :- 1-Mini book(Small Album), 2-Save the date video and image, 2-Table Calendar"],
        list3: ["Booking amount - Rs. 10k", "50% Advance Payment Before Work", "25% After Program", "25% With deliverables Item"],
        list4: [" Cinematic Camera", "Cinematic Camera With Gimbal", "Traditional Photography", "Candid Photography", "Studio Light (As Per requiremant)", " Drone (As Per requiremant)"]
    },
]

export default services;