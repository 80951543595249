import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiSolidStar } from 'react-icons/bi';
import { toast } from 'react-toastify';
import apiList from '../libs/apiList';
import { useNavigate } from 'react-router-dom';
import authContext from '../context/auth/authContext';

const Ratings = () => {

    const context = useContext(authContext);
    const { user } = context;

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Rate Us - SP';
        getFeedbacks();
        // eslint-disable-next-line
    }, []);

    const navigate = useNavigate(null);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [open]);

    const dummyReview = [
        {
            name: 'Senha Raj',
			feedback: 'The team was responsive, and incredibly talented. The photos from our event are absolutely beautiful! We will definitely be using ShutterPics for future events!',
			rate: 5
        },
        {
            name: 'Priya kr.',
            feedback: 'ShutterPics truly exceeded our expectations on our wedding day. The photos captured every emotion and detail perfectly.',
            rate: 4
        },
        {
            name: 'Rohan',
			feedback: 'I hired ShutterPics for a corporate event, and they delivered outstanding work. The photographers were punctual, professional, and captured the essence of our brand beautifully. The final images were exactly what we needed for our marketing materials. Highly recommended!',
			rate: 5
        },
        {
            name: 'Anjali',
			feedback: 'The family photoshoot was an amazing experience. The photographer was so patient with our kids & managed to capture the most genuine smiles. Thank you, ShutterPics!',
			rate: 4
        },
        {
            name: 'Arjun Paswan',
            feedback: 'ShutterPics did a fantastic job with our engagement photos. The photographer had a great eye for detail and made the whole session so much fun!',
            rate: 4
        }
        
    ]

    const [feedData, setFeedData] = useState(dummyReview);

    // const dummyReview = ;

    const handleOpen = () => {
        if (localStorage.getItem("authtoken")) {
            setOpen(true);
        }
        else {
            toast.warn("Please get logged in first");
            navigate("/shutterpics-login");
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [ratingDetails, setratingDetails] = useState({
        name: user.name,
        feedback: '',
        rate: 0
    });
    const [spinSingUpLoading, setSpinSingUpLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleOnChange = (key, value) => {
        setratingDetails({
            ...ratingDetails,
            [key]: value
        });
    };

    const handleRate = (idx) => {
        setratingDetails({
            ...ratingDetails,
            rate: idx + 1
        });
    };

    const handleFeedback = async () => {
        if (!ratingDetails.feedback) {
            return toast.warn('Please write feedback for us');
        }
        if (ratingDetails.rate === 0) {
            return toast.warn('Please give rating stars');
        }

        if (!isClicked) {
            try {
                setSpinSingUpLoading(true);
                setIsClicked(true);

                const response = await fetch(apiList.rateus, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authtoken': localStorage.getItem("authtoken")
                    },
                    body: JSON.stringify({ name: user.name, feedback: ratingDetails.feedback, rate: ratingDetails.rate })
                });

                const json = await response.json();
                if (json.success) {
                    toast.success(json.message);
                    setSpinSingUpLoading(false);
                    setratingDetails({ feedback: '', rate: 0 });
                    setOpen(false);
                    getFeedbacks();
                } else {
                    toast.error(json.message);
                    setSpinSingUpLoading(false);
                    setIsClicked(false);
                    setOpen(false);
                    setratingDetails({ feedback: '', rate: 0 });
                }
            } catch (err) {
                toast.warn('Internal Server Error');
                setSpinSingUpLoading(false);
                setIsClicked(false);
                setOpen(false);
                setratingDetails({ feedback: '', rate: 0 });
            }
        }

    };

    const getFeedbacks = async () => {
        try {
            const response = await fetch(apiList.getfeedback, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const json = await response.json();
            if (json.success) {
                setFeedData(dummyReview.concat(json.ratings))
            }

        } catch (err) {
            toast.warn('Internal Server Error');
        }

    };

    return (
        <div className="bg-gradient-to-tl from-purple-200 to-blue-200 pb-6 sm:pb-8 lg:pb-12 p-4 text-gray-800">
            <Helmet>
                <title>
                    ShutterPics Booking: Professional Photography Services | Top-Rated Photoshoots |
                    Videography | Music Video Production | Trusted Photography Studio
                </title>
                <meta
                    name="description"
                    content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
                />
            </Helmet>
            <section>
                <div className="container pb-12 mx-auto">
                    <div className="flex flex-col text-center w-full mb-4">
                        <h1 className="text-3xl md:text-4xl font-bold">Feedback And Ratings</h1>
                    </div>

                    <div className='text-center my-4'>
                        <button onClick={handleOpen} className='bg-purple-500 hover:bg-purple-600 text-white py-2 px-10 rounded-lg' to="/rateus">Give Feedback</button>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {feedData.map((data, idx) => {
                            return (
                                <div key={idx} className="p-2">
                                    <div className="flex border p-3 bg-gray-100 rounded-lg">
                                        <div className="flex-grow">
                                            <div className="title-font font-medium flex items-center justify-between">
                                                <h2>{data.name}</h2> <span className='flex items-center justify-center space-x-1'>
                                                    {
                                                        [0, 1, 2, 3, 4].map((idx) => {
                                                            return <BiSolidStar key={idx} className={`${data.rate > idx ? "text-yellow-400" : "text-gray-300"}`} />
                                                        })
                                                    }
                                                </span>
                                            </div>
                                            <p className="text-gray-500 h-32 overflow-y-auto nobar">{data.feedback}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {
                        feedData.length > 12 && <div onClick={handleOpen} className='text-center mt-10'>
                            <button className='bg-purple-500 hover:bg-purple-600 text-white py-2 px-10 rounded-lg' to="/rateus">Give Feedback</button>
                        </div>
                    }

                </div>
            </section>

            <section onClick={handleClose} className={`fixed inset-0 h-full w-full z-50 flex items-center justify-center transition-colors ease-in-out duration-500 ${open ? 'bg-black/80 pointer-events-auto' : 'invisible pointer-events-none'}`} >
                <div className="px-4 w-full md:max-w-md" onClick={(e) => { e.stopPropagation(); }}>
                    <div className={`rounded-lg bg-gray-200 w-full p-4 transition-all ease-in duration-100 ${open ? 'scale-100' : 'scale-50'}`} >
                        <div className="font-semibold text-2xl text-center mb-2">
                            Feedback
                        </div>

                        <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                            <div className={`space-y-2`}>

                                <div className='flex items-center justify-center space-x-2'>
                                    {
                                        [0, 1, 2, 3, 4].map((idx) => {
                                            return <BiSolidStar size={30} onClick={() => handleRate(idx)} key={idx} className={`${ratingDetails.rate > idx ? "text-yellow-400" : "text-gray-400"} cursor-pointer`} />
                                        })
                                    }
                                </div>

                                <div className="flex">
                                    <textarea
                                        type="text"
                                        id="feedback"
                                        name="feedback"
                                        autoComplete="feedback"
                                        required
                                        placeholder="Write feedback message for us"
                                        className={`appearance-none rounded-md relative flex-1 h-28 w-full px-3 py-2 border bg-white/50 border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                                        value={ratingDetails.feedback}
                                        onChange={(event) => {
                                            handleOnChange('feedback', event.target.value);
                                        }}
                                    />
                                </div>

                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className={`text-center p-2 rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 w-full flex items-center justify-center space-x-4`}
                                    onClick={handleFeedback}
                                >
                                    <span>Add Feedback</span>
                                    {spinSingUpLoading && (
                                        <AiOutlineLoading3Quarters className="animate-spin" aria-hidden="true" />
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Ratings;