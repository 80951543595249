import React, { useEffect, useState } from 'react';
import { generateDate, months } from '../libs/GenerateData';
import dayjs from 'dayjs';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { toast } from 'react-toastify';
import apiList from '../libs/apiList';
import { Helmet } from 'react-helmet';

const Calendar = ({ setBookedDate }) => {
	const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

	const currentDate = dayjs();
	const [today, setToday] = useState(currentDate);
	const [notAvailableDate, setNotAvailableDate] = useState([]);

	const handlePrev = () => {
		if (today.month() <= currentDate.month() && today.year() === currentDate.year()) {
			toast.warn("Cann't book for previous month");
		} else {
			setToday(today.month(today.month() - 1));
		}
	};

	useEffect(() => {
		checkAvailableDate();
		// eslint-disable-next-line
	}, []);

	const checkAvailableDate = async () => {
		try {
			const response = await fetch(apiList.getslots, {
				method: 'GET'
			});

			const json = await response.json();
			if (json.success) {
				const date = json.data.map((data) => {
					return new Date(data);
				});
				setNotAvailableDate(date);
			} else {
				toast.error(json.message);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleDateClick = (date, currentMonth) => {
		if (date < today && !currentMonth && !(today <= currentDate)) {
			setToday(today.month(today.month() - 1));
		} else if (date > today && !currentMonth) {
			setToday(today.month(today.month() + 1));
		} else {
			if (date < currentDate) {
				toast.warn("Cann't book before current date");
			} else {
				for (let i = 0; i < notAvailableDate.length; i++) {
					// eslint-disable-next-line
					if (notAvailableDate[i].getTime() == date) {
						toast.warn(
							`Sorry, We are not availble on ${date.date()} ${months[date.month()]}, ${date.year()}`
						);
						setBookedDate(null);
						return;
					}
				}

				toast(`Available on ${date.date()} ${months[date.month()]}, ${date.year()}`);
				setBookedDate(date);
			}
		}
	};

	return (
		<div className="w-80 md:w-96 border border-purple-500 bg-gradient-to-tl from-gray-100 to-gray-50 rounded-xl">
			<Helmet>
				<title>
					ShutterPics Calendar: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="flex justify-between items-center border-b border-dashed border-purple-400 p-3">
				<h1 className="font-semibold">
					{months[today.month()]}, {today.year()}
				</h1>
				<div className="flex items-center gap-3">
					<GrFormPrevious
						className="cursor-pointer hover:text-white hover:bg-gray-700 rounded-full transition-all duration-300"
						onClick={handlePrev}
					/>
					<h1
						className="cursor-pointer"
						onClick={() => {
							setToday(currentDate);
						}}
					>
						Today
					</h1>
					<GrFormNext
						className="cursor-pointer hover:text-white hover:bg-gray-700 rounded-full transition-all duration-300"
						onClick={() => {
							setToday(today.month(today.month() + 1));
						}}
					/>
				</div>
			</div>

			<div className="w-full grid grid-cols-7 ">
				{days.map((day, key) => {
					return (
						<div
							key={key}
							className="h-12 grid place-content-center text-sm font-bold text-gray-500"
						>
							{day}
						</div>
					);
				})}
			</div>

			<div className="w-full grid grid-cols-7">
				{generateDate(today.month(), today.year()).map(({ date, currentMonth, today }, idx) => {
					return (
						<div
							onClick={() => {
								handleDateClick(date, currentMonth);
							}}
							key={idx}
							className="h-12 grid place-content-center text-sm text-black"
						>
							<h1
								className={`${!currentMonth && 'text-gray-400'} ${
									today && 'bg-purple-500 text-white'
								} h-10 w-10 grid place-content-center rounded-full transition-all duration-200 hover:bg-gray-400 hover:text-white cursor-pointer`}
							>
								{date.date()}
							</h1>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Calendar;
