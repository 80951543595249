import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsBookmarkCheckFill } from 'react-icons/bs';
import About from './About';
import { Helmet } from 'react-helmet';
import { BiSolidStar } from 'react-icons/bi';

const HomePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'Home Page - SP';
	}, []);

	const teamData = [
		{
			name: 'Ashish',
			position: 'Photographer',
			about: 'Captures moments with creativity, turning them into memories.',
			imgLink:
				'https://media.greatbigphotographyworld.com/wp-content/uploads/2022/04/photographer-holding-a-nikon-camera-768x512.jpg'
		},
		{
			name: 'Priyanshu',
			position: 'Designer',
			about: 'Provides expert backup, capturing every angle to ensure nothing is missed.',
			imgLink:
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBsWvQyUjaEYSM_e11uEuBFU31jwYtbkU9Gg&s'
		},
		{
			name: 'Prakash',
			position: 'Videography',
			about: 'Crafts cinematic stories, turning your special day into a timeless film.',
			imgLink:
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBsWvQyUjaEYSM_e11uEuBFU31jwYtbkU9Gg&s'
		},
		{
			name: 'Pratik',
			position: 'Editor',
			about: 'Polishes each photo and video, enhancing every detail to perfection.',
			imgLink:
				'https://media.greatbigphotographyworld.com/wp-content/uploads/2022/04/photographer-holding-a-nikon-camera-768x512.jpg'
		},
		{
			name: 'Nikhil',
			position: 'Support staff',
			about: 'Ensures smooth operations, providing seamless service and assistance.',
			imgLink:
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBsWvQyUjaEYSM_e11uEuBFU31jwYtbkU9Gg&s'
		}
	];

	const dummyReview = [
		{
			name: 'Sneha Raj',
			about: 'The team was responsive, and incredibly talented. The photos from our event are absolutely beautiful! We will definitely be using ShutterPics for future events!',
			rating: 5
		},
		{
			name: 'Priya k.',
			about: 'ShutterPics truly exceeded our expectations on our wedding day. The photos captured every emotion and detail perfectly.',
			rating: 4
		},
		{
			name: 'Rohan',
			about: 'I hired ShutterPics for a corporate event, and they delivered outstanding work. The photographers were punctual, professional, and captured the essence of our brand beautifully. The final images were exactly what we needed for our marketing materials. Highly recommended!',
			rating: 5
		},
		{
			name: 'Anjali',
			about: 'The family photoshoot was an amazing experience. The photographer was so patient with our kids & managed to capture the most genuine smiles. Thank you, ShutterPics!',
			rating: 4
		},
		{
			name: 'Arjun Paswan',
            about: 'ShutterPics did a fantastic job with our engagement photos. The photographer had a great eye for detail and made the whole session so much fun!',
            rating: 4
		}
	];

	return (
		<div className="bg-gradient-to-tl from-purple-200 to-blue-200 pb-6 sm:pb-8 lg:pb-12 relative overflow-hidden">
			<Helmet>
				<title>
					ShutterPics Homepage: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="mx-auto px-4 md:px-8 text-gray-700 lg:pt-12">
				<section className="mb-16 flex flex-col justify-start md:mb-24 lg:flex-row my-4 md:my-8">
					<div className="flex flex-col justify-center sm:text-center lg:text-left w-full pt-4 pr-2">
						<h1 className="mb-2 text-4xl font-bold sm:text-5xl md:mb-4">
							Capture Your Moments with Us
						</h1>

						<p className="mb-4 font-semibold md:text-lg text-justify">
							Whether it's a wedding, family event, or personal photoshoot, our professional
							photographers are here to make your memories last a lifetime.
						</p>

						<Link
							to="/shutterpics-online-booking"
							className="bg-purple-500 hover:bg-purple-600 md:w-80 text-center text-white py-2 rounded-lg mb-12"
						>
							Book your slot now <BsBookmarkCheckFill className="inline-block scale-110 ml-2" />
						</Link>
					</div>

					<div className="w-full flex items-center lg:w-4/5">
						<div className="flex flex-col w-full pr-2">
							<div className="place-self-end h-40 md:w-36 bg-white rounded-lg overflow-hidden">
								<img
									src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBsWvQyUjaEYSM_e11uEuBFU31jwYtbkU9Gg&s"
									loading="lazy"
									alt="myLibrary"
									className="h-full w-full object-cover mix-blend-darken object-center"
								/>
							</div>
							<div className="h-44 mt-4 place-self-start bg-white rounded-lg overflow-hidden">
								<img
									src="https://media.greatbigphotographyworld.com/wp-content/uploads/2022/04/photographer-holding-a-nikon-camera-768x512.jpg"
									loading="lazy"
									alt="myLibrary"
									className="h-full w-full object-cover mix-blend-darken object-center"
								/>
							</div>
						</div>

						<div className="h-64 w-full bg-white rounded-lg overflow-hidden ml-2">
							<img
								src="https://img.freepik.com/free-photo/hand-holding-photo-camera_23-2150630632.jpg"
								loading="lazy"
								alt="myLibrary"
								className="h-full w-full object-cover mix-blend-darken object-center"
							/>
						</div>
					</div>
				</section>

				{/* <section className="flex items-center justify-center border-t py-8">
          <div className="-mx-6 grid grid-cols-2 gap-8 md:flex items-center justify-around w-full">
            <div className="px-6 md:px-8">
              <span className="block text-center text-4xl font-bold text-blue-600">20</span>
              <span className="block text-center text-xl font-semibold">People</span>
            </div>

            <div className="px-6 md:px-8">
              <span className="block text-center text-4xl font-bold text-blue-600">30+</span>
              <span className="block text-center text-xl font-semibold">Cities</span>
            </div>

            <div className="px-6 md:px-8">
              <span className="block text-center text-4xl font-bold text-blue-600">50+</span>
              <span className="block text-center text-xl font-semibold">Libraries</span>
            </div>

            <div className="px-6 md:px-8">
              <span className="block text-center text-4xl font-bold text-blue-600">2500+</span>
              <span className="block text-center text-xl font-semibold">Students</span>
            </div>

          </div>
        </section> */}

				<About />

				<section className="">
					<div className="container pb-12 mx-auto">
						<div className="flex flex-col text-center w-full mb-4">
							<h1 className="text-3xl md:text-4xl font-bold mb-2">OUR TEAM</h1>
							<p className="lg:w-4/5 lg:text-center lg:text-lg lg:font-semibold w-full mx-auto text-justify">
								In any bussiness team is a greatest asset. In our team each member brings a unique
								blend of talent, creativity, and dedication to ensure that every moment we capture
								is truly special. Get to know the passionate professionals behind the lens!
							</p>
						</div>
						<div className="flex flex-wrap">
							{teamData.map((data) => {
								return (
									<div key={data.position} className="p-2 xl:w-1/3 md:w-1/2 w-full">
										<div className="h-full flex items-center border p-4 bg-gray-100 rounded-lg">
											<img
												alt="team"
												className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
												src={data.imgLink}
											/>
											<div className="flex-grow">
												<h2 className="title-font font-medium">
													{data.name} ({data.position})
												</h2>
												<p className="text-gray-500">{data.about}</p>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>

				<section>
					<div className="container pb-12 mx-auto">
						<div className="flex flex-col text-center w-full mb-4">
							<h1 className="text-3xl md:text-4xl font-bold mb-2">Feedback And Ratings</h1>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
							{dummyReview.map((data, idx) => {
								return (
									<div key={idx} className="p-2">
										<div className="flex border p-3 bg-gray-100 rounded-lg">
											<div className="flex-grow">
												<div className="title-font font-medium flex items-center justify-between">
													<h2>{data.name}</h2> <span className='flex items-center justify-center space-x-1'>
														{
															[0, 1, 2, 3, 4].map((idx) => {
																return <BiSolidStar key={idx} className={`${data.rating > idx ? "text-yellow-400" : "text-gray-300"}`} />
															})
														}
													</span>
												</div>
												<p className="text-gray-500 h-28 overflow-y-auto nobar">{data.about}</p>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div className='text-center mt-10'>
							<Link className='bg-purple-500 hover:bg-purple-600 text-white py-2 px-10 rounded-lg' to="/rateus">See More</Link>
						</div>
					</div>
				</section>

			</div>
		</div>
	);
};

export default HomePage;
