import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SoloService = (props) => {
	const { service, idx } = props;
	const [isClicked, setIsClicked] = useState(false);

	const navigate = useNavigate(null);

	return (
		<div className="bg-transparent h-96 rounded-xl perspective-1000">
			<Helmet>
				<title>
					ShutterPics Services: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div
				className={`relative w-full h-full preserve-3d ${isClicked && 'rotate-y-180'} duration-500`}
			>
				<div
					className="w-full h-full absolute overflow-hidden"
					onClick={() => {
						setIsClicked(!isClicked);
					}}
				>
					<img src={service.imgLink} alt="" className="w-full h-full object-cover rounded-xl" />

					<div className="absolute inset-0 w-full h-full bg-black/60 rounded-xl"></div>

					<h1
						className={`text-2xl md:text-3xl font-bold absolute top-0 left-0 w-full text-center p-4 ${
							isClicked && 'hidden'
						}`}
					>
						{service.heading}{' '}
						<span className="max-md:inline-block">
							(<span className="text-xl md:text-2xl underline">{service.subh1}</span>)
						</span>
					</h1>

					<div
						className={`absolute bottom-0 p-4 flex justify-center w-full ${isClicked && 'hidden'}`}
					>
						<button className="rounded-lg py-1.5 px-10 bg-purple-500 hover:bg-purple-600">
							See details
						</button>
					</div>
				</div>

				<div
					onClick={() => {
						setIsClicked(!isClicked);
					}}
					className="absolute rotate-y-180 w-full h-full bg-gradient-to-tl to-blue-300 from-purple-300 text-gray-700 p-4 bg-opacity-80 rounded-xl backface-hidden overflow-y-auto nobar"
				>
					<div className="flex flex-col space-y-4">
						<div>
							<h1 className="text-2xl md:text-3xl font-bold">
								{service.heading} (
								<span className="text-xl md:text-2xl underline">{service.subh1}</span>){' '}
							</h1>

							<h1 className="text-2xl md:text-3xl font-bold">
								{service.subh4}{' '}
								<span className="text-xl md:text-2xl underline">{service.subh5}</span>
							</h1>
							<ol className="flex flex-col list-decimal px-4">
								{service.list1.map((disc, idx) => {
									return <li key={idx}>{disc}</li>;
								})}
							</ol>
						</div>

						{idx === 3 && (
							<div>
								<h1 className="text-2xl md:text-3xl font-bold">{service.subh6}</h1>
								<ol className="flex flex-col list-decimal px-4">
									{service.list4.map((disc, idx) => {
										return <li key={idx}>{disc}</li>;
									})}
								</ol>
							</div>
						)}

						<div>
							<h1 className="text-2xl md:text-3xl font-bold">{service.subh2}</h1>
							<ol className="flex flex-col list-decimal px-4">
								{service.list2.map((disc, idx) => {
									return <li key={idx}>{disc}</li>;
								})}
							</ol>
						</div>

						<div>
							<h1 className="text-2xl md:text-3xl font-bold">{service.subh3}</h1>
							<ol className="flex flex-col list-decimal px-4">
								{service.list3.map((disc, idx) => {
									return <li key={idx}>{disc}</li>;
								})}
							</ol>
						</div>

						<div className="text-white text-center">
							<button
								onClick={() => {
									navigate('/shutterpics-online-booking', { state: { eventIdx: idx } });
								}}
								className="bg-purple-500 hover:bg-purple-600 py-2 px-20 rounded-lg inline-block text-center"
							>
								Book Now
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SoloService;
