import React, { useEffect } from 'react';
import services from '../libs/AllServices';
import SoloService from './SoloService';
import { Helmet } from 'react-helmet';

const Services = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'Services - SP';
	}, []);

	return (
		<div className="bg-gradient-to-tl from-purple-200 to-blue-200 pb-6 sm:pb-8 lg:pb-12">
			<Helmet>
				<title>
					ShutterPics Services: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="mx-auto max-w-screen-2xl p-4 md:px-8 text-gray-700">
				<div className="flex flex-col items-center mb-4 lg:mb-8">
					<h1 className="text-3xl lg:text-4xl font-bold mb-2">Services</h1>
					<p className="max-md:text-justify text-center lg:w-3/5">
						Discover Shutterpics, your go-to photography service for all occasions. We specialize in
						professional photoshoots, birthdays, weddings, mehndi, music videos, and more, ensuring
						high-quality, memorable photos every time. Trust us to capture your special moments with
						creativity and precision, making them unforgettable.
					</p>
				</div>

				<section className="grid md:grid-cols-2 gap-14 text-white">
					{services.map((service, idx) => {
						return <SoloService key={idx} service={service} idx={idx} />;
					})}
				</section>

				<section className="my-12">
					<div className="mx-auto p-4 rounded-lg lg:w-4/5 bg-gradient-to-tl from-purple-400 to-blue-400 text-lg lg:text-xl lg:text-center text-white">
						<span className="font-bold">Note :-</span> Deliverable items will be delivered 30 to 45
						days after the final selected photos are received.
					</div>
				</section>
			</div>
		</div>
	);
};

export default Services;
