import React, { useEffect, useState } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiList from '../libs/apiList';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'Contact Us - SP';
	}, []);

	const [isClicked, setIsClicked] = useState(false);
	const [spinSingUpLoading, setSpinSingUpLoading] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (open) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}

		return () => {
			document.body.classList.remove('modal-open');
		};
	}, [open]);

	const [contactIfo, setContactInfo] = useState({
		name: '',
		email: '',
		contactnum: '',
		address: '',
		message: ''
	});

	const handleOnChange = (key, value) => {
		setContactInfo({
			...contactIfo,
			[key]: value
		});
	};

	const handleSendMessage = async () => {
		if (
			!contactIfo.name ||
			!contactIfo.email ||
			!contactIfo.contactnum ||
			!contactIfo.address ||
			!contactIfo.address
		) {
			return toast.warn('Fill all the details carefully');
		}

		setSpinSingUpLoading(true);

		if (!isClicked) {
			try {
				setIsClicked(true);
				const response = await fetch(apiList.contactmessage, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(contactIfo)
				});

				const json = await response.json();
				if (json.success) {
					setIsClicked(false);
					setOpen(true);
					setContactInfo({
						name: '',
						email: '',
						contactnum: '',
						address: '',
						message: ''
					});
					setSpinSingUpLoading(false);
					// toast.success(json.message);
				} else {
					toast.error(json.message);
					setIsClicked(false);
					setSpinSingUpLoading(false);
				}
			} catch (err) {
				toast.warn('Internal Server Error');
				setIsClicked(false);
				setSpinSingUpLoading(false);
			}
		}
	};

	return (
		<div className="bg-gradient-to-tl from-purple-200 to-blue-200 pb-6 sm:pb-8 lg:pb-12">
			<Helmet>
				<title>
					ShutterPics Contact: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="mx-auto max-w-screen-2xl px-4 md:px-8 text-gray-700">
				<section className="flex items-center justify-around lg:space-x-3 flex-col lg:flex-row lg:mx-8 mb-12">
					<div className="flex flex-col lg:w-3/5 p-2">
						<div className="my-4">
							<h1 className="text-3xl lg:text-4xl font-bold mb-2">Contact Us On</h1>
							<p className="mb-10 font-semibold text-justify">
								We'd love to hear from you! Whether you have a question, want to book a session, or just want to learn more about what we do at ShutterPics, we're here to help. Reach out to us using the form below, call us, or whatsapp us, and we'll get back to you as soon as possible.
							</p>
							<ul className="flex max-lg:justify-center flex-wrap text-white">
								<Link
									to="tel:9125907656"
									className="flex items-center justify-center space-x-4 border bordergra4border-gray-300 p-2 rounded-lg bg-gray-500 hover:bg-gray-600 w-32 m-2"
								>
									Call <BiSolidPhoneCall className="ml-1.5" />{' '}
								</Link>

								<Link
									to="https://wa.me/91+9125907656"
									className="flex items-center justify-center space-x-4 border border-green-300 p-2 rounded-lg bg-green-500 hover:bg-green-600 w-32 m-2"
								>
									WhatsApp <IoLogoWhatsapp className="ml-1.5" />{' '}
								</Link>
							</ul>
						</div>
					</div>

					<div className="lg:w-2/5 h-60 lg:h-80 py-2 md:py-4 flex items-center justify-center">
						<div className="border border-blue-300 p-4 rounded-md bg-gradient-to-tl from-purple-300 to-blue-300">
							<div className="flex items-center justify-around pb-4">
								<Link to="tel:9125907656">
									<BiSolidPhoneCall size={25} className="text-white rounded-md bg-gray-500 p-1" />
								</Link>
								<h1 className="text-2xl font-bold text-center">shutterpics.in</h1>
								<Link to="https://wa.me/91+9125907656">
									<IoLogoWhatsapp size={25} className="text-white rounded-md bg-green-500 p-1" />
								</Link>
							</div>
							<p>
								{' '}
								<span className="font-bold">Contact On : </span>{' '}
								<Link to="tel:9125907656">9125907656</Link>{' '}
							</p>
							<p>
								{' '}
								<span className="font-bold">E-mail :</span> <span>shutterpicsstudio@gmail.com</span>{' '}
							</p>
							<p>
								<span className="font-bold">Address : </span>{' '}
								<span>Bazaria Nihal Chand, Farrukhabad, U.P. (209625)</span>{' '}
							</p>

							<p className="text-sm text-center -mb-3 mt-2">Contact us for the more details</p>
						</div>
					</div>
				</section>

				<section className="my-16">
					<div>
						<h2 className="text-center text-3xl lg:text-4xl font-bold mb-2">
							Fill Up The Contact Info.
						</h2>
					</div>
					<div className="flex items-center justify-center">
						<div className="max-w-xl w-full">
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								className="space-y-3"
							>
								<div className="flex max-md:flex-col justify-between max-md:space-y-3 md:space-x-8">
									<label htmlFor="name" className="w-full">
										Name
										<input
											type="text"
											id="name"
											className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
											placeholder="Enter your name"
											value={contactIfo.name}
											onChange={(event) => {
												handleOnChange('name', event.target.value);
											}}
										/>
									</label>
									<label htmlFor="contact" className="w-full">
										Contact No.
										<input
											type="text"
											id="contact"
											className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
											placeholder="Enter your contact number"
											value={contactIfo.contactnum}
											onChange={(event) => {
												handleOnChange('contactnum', event.target.value);
											}}
										/>
									</label>
								</div>

								<div className="flex max-md:flex-col justify-between max-md:space-y-3 md:space-x-8">
									<label htmlFor="email" className="w-full">
										E-mail Address
										<input
											type="email"
											id="email"
											className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
											placeholder="Enter your email address"
											value={contactIfo.email}
											onChange={(event) => {
												handleOnChange('email', event.target.value);
											}}
										/>
									</label>
									<label htmlFor="address" className="w-full">
										Address
										<input
											id="address"
											type="text"
											className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
											placeholder="Enter your address"
											value={contactIfo.address}
											onChange={(event) => {
												handleOnChange('address', event.target.value);
											}}
										/>
									</label>
								</div>

								<div className="flex flex-col items-center space-y-10">
									<label htmlFor="message" className="w-full">
										Message
										<textarea
											id="message"
											type="text"
											className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
											placeholder="Write message for us"
											value={contactIfo.message}
											onChange={(event) => {
												handleOnChange('message', event.target.value);
											}}
										/>
									</label>

									<button
										type="submit"
										className="text-center p-2 rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 w-full md:w-3/5 flex items-center justify-center space-x-2"
										onClick={handleSendMessage}
									>
										<span>Submit</span>
										{spinSingUpLoading && (
											<AiOutlineLoading3Quarters className="animate-spin" aria-hidden="true" />
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</section>

				<section
					onClick={() => {
						setOpen(!open);
					}}
					className={`fixed inset-0 h-full w-full z-50 flex items-center justify-center transition-colors ease-in-out duration-500 ${open ? 'bg-black/80 pointer-events-auto' : 'invisible pointer-events-none'
						}`}
				>
					<div
						className="px-4"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div
							className={`rounded-lg bg-gray-200 p-2 transition-all ease-in duration-100 ${open ? 'scale-100' : 'scale-50'
								}`}
						>
							<div className="text-center mb-4">
								<p className="p-1 text-xl font-semibold">We will contact you soon</p>
								<p className="md:w-4/5 mx-auto">
									On your given contact deatails our team will contact your for the further
									conversation.
								</p>
							</div>

							<div className="text-center py-2">
								<button
									type="submit"
									onClick={() => {
										setOpen(!open);
									}}
									className="text-center p-2 rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 w-2/5"
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Contact;
