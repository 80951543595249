import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiList from '../libs/apiList';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import authContext from '../context/auth/authContext';
import { Helmet } from 'react-helmet';

const Signup = () => {
	const context = useContext(authContext);
	const { setIsLoggedin, getUser } = context;

	const [signupDetails, setSignupDetails] = useState({
		name: '',
		email: '',
		password: '',
		confPassword: '',
		contactnum: '',
		address: '',
		otp: '',
		type: 'customer'
	});

	let navigate = useNavigate();

	const [spinSingUpLoading, setSpinSingUpLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [isOTPSend, setIsOTPSend] = useState(false);
	const [resendOTP, setResend] = useState(false);
	const [timeOut, setTimeOut] = useState(null);
	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'User Signup - SP';
	}, []);

	const handleOnChange = (key, value) => {
		setSignupDetails({
			...signupDetails,
			[key]: value
		});
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleLogin = async () => {
		if (
			!signupDetails.name ||
			!signupDetails.email ||
			!signupDetails.contactnum ||
			!signupDetails.address ||
			!signupDetails.password ||
			!signupDetails.confPassword
		) {
			return toast.warn('Fill all the details carefully');
		}

		if (signupDetails.password !== signupDetails.confPassword) {
			return toast.warn('Confirm password do not match');
		}

		if (!isClicked) {

			const id = setTimeout(() => {
				if (isClicked) {
					setResend(true);
				}
			}, 15000);
			setTimeOut(id);

			if (!isOTPSend) {
				try {
					setIsClicked(true);
					setSpinSingUpLoading(true);
					const response = await fetch(apiList.usersignup, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(signupDetails)
					});

					const json = await response.json();
					if (json.success) {
						toast.success(`OTP send to ${signupDetails.email}`);
						setSpinSingUpLoading(false);
						setIsOTPSend(!isOTPSend);
						setIsClicked(false);
					} else {
						toast.error(json.message);
						setSpinSingUpLoading(false);
						setIsClicked(false);
						clearTimeout(timeOut);
					}
				} catch (err) {
					toast.warn('Internal Server Error');
					setSpinSingUpLoading(false);
					setIsClicked(false);
					clearTimeout(timeOut);
				}
			} else if (isOTPSend && signupDetails.otp !== '') {
				try {
					setSpinSingUpLoading(true);
					setIsClicked(true);
					const response = await fetch(apiList.verifyotp, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(signupDetails)
					});

					const json = await response.json();
					if (json.success) {
						localStorage.setItem('authtoken', json.authtoken);
						localStorage.setItem('type', json.type);
						setIsLoggedin(true);
						getUser();
						setSpinSingUpLoading(false);
						setIsClicked(false);
						toast.success(json.message);
						toast.success('Welcome to the Shutter Pics family!');
						navigate('/');
					} else {
						toast.warn(json.message);
						setSpinSingUpLoading(false);
						setIsClicked(false);
					}
				} catch (err) {
					setSpinSingUpLoading(false);
					setIsClicked(false);
				}
			}
		}
	};

	const handleResendOTP = async () => {
		await setIsClicked(false);
		handleLogin();
	}

	return (
		<div className="min-h-screen bg-gradient-to-tl from-purple-200 to-blue-200 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
			<Helmet>
				<title>
					ShutterPics SignUp: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div
				className={`max-w-screen-md w-full space-y-2 shadow-lg shadow-gray-400 rounded-xl p-4 pb-4 bg-white/20 relative`}
			>
				<div className="space-y-1" data-aos="zoom-in" data-aos-duration="500">
					<div className="flex justify-center">
						<div className="w-14 h-14 overflow-hidden rounded-full shadow-lg shadow-gray-600">
							<img
								src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg"
								alt="Profile"
								className="w-full h-full object-cover"
							/>
						</div>
					</div>
					<h2 className={`text-center text-2xl font-extrabold`}>Sign up for an account</h2>
				</div>

				<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
					<div className={`space-y-2`}>
						<div className="flex flex-col md:flex-row max-md:space-y-2 md:space-x-8">
							<div className="w-full">
								<label htmlFor="name" className="block ml-1">
									Name
								</label>
								<input
									id="name"
									name="name"
									type="text"
									autoComplete="name"
									required
									className="appearance-none bg-white/50 rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
									placeholder="Enter your name"
									value={signupDetails.name}
									onChange={(event) => {
										handleOnChange('name', event.target.value);
									}}
								/>
							</div>
							<div className="w-full">
								<label htmlFor="contact" className="block ml-1">
									Contact No.
								</label>
								<input
									id="contact"
									name="contact"
									type="text"
									autoComplete="contact"
									required
									className="appearance-none bg-white/50 rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
									placeholder="Enter your contact number"
									value={signupDetails.contactnum}
									onChange={(event) => {
										handleOnChange('contactnum', event.target.value);
									}}
								/>
							</div>
						</div>

						<div className="flex flex-col md:flex-row max-md:space-y-2 md:space-x-8">
							<div className="w-full">
								<label htmlFor="address" className="block ml-1">
									Address
								</label>
								<div className="flex">
									<input
										type="text"
										id="address"
										name="address"
										autoComplete="address"
										required
										placeholder="Enter your address"
										className={`appearance-none bg-white/50 rounded-md relative flex-1 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm`}
										value={signupDetails.address}
										onChange={(event) => {
											handleOnChange('address', event.target.value);
										}}
									/>
								</div>
							</div>
							<div className="w-full">
								<label htmlFor="phone" className="block ml-1">
									E-mail
								</label>
								<div className="flex">
									<input
										type="email"
										id="email"
										name="email"
										autoComplete="email"
										required
										placeholder="Enter your email address"
										className={`appearance-none bg-white/50 rounded-md relative flex-1 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm`}
										value={signupDetails.email}
										onChange={(event) => {
											handleOnChange('email', event.target.value);
										}}
									/>
								</div>
							</div>
						</div>

						<div className="flex flex-col md:flex-row max-md:space-y-2 md:space-x-8">
							<div className="relative w-full">
								<label htmlFor="password" className="block ml-1">
									Password
								</label>
								<input
									id="password"
									name="password"
									type={showPassword ? 'text' : 'password'}
									autoComplete="current-password"
									required
									className="appearance-none bg-white/50 rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
									placeholder="Enter Password"
									value={signupDetails.password}
									onChange={(event) => {
										handleOnChange('password', event.target.value);
									}}
								/>
								<button
									type="button"
									className="absolute inset-y-0 top-6 right-5 flex items-center focus:outline-none z-10 text-black"
									onClick={togglePasswordVisibility}
								>
									{showPassword ? <FaEye /> : <FaEyeSlash />}
								</button>
							</div>

							<div className="w-full">
								<label htmlFor="confirmPassword" className="block ml-1">
									Confirm Password
								</label>
								<input
									id="confirmPassword"
									name="confirmPassword"
									type="password"
									autoComplete="new-password"
									required
									className="appearance-none rounded-md relative block w-full px-3 py-2 border bg-white/50 border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
									placeholder="Re-enter the password"
									value={signupDetails.confPassword}
									onChange={(event) => {
										handleOnChange('confPassword', event.target.value);
									}}
								/>
							</div>
						</div>

						<div className="flex flex-col md:flex-row max-md:space-y-8 items-end md:space-x-8">
							<div className="w-full">
								<label htmlFor="otp" className="block ml-1">
									OTP Verification
								</label>
								<input
									id="otp"
									name="otp"
									type="text"
									autoComplete="otp"
									required
									disabled={!isOTPSend}
									className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none bg-white/50 focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
									placeholder="****"
									value={signupDetails.otp}
									onChange={(event) => {
										handleOnChange('otp', event.target.value);
									}}
								/>
							</div>

							<div className="w-full">
								<div className='text-end mb-0.5'>
									{
										resendOTP && <span onClick={handleResendOTP} className='text-sm text-gray-700 cursor-pointer'>Resent OTP</span>
									}
								</div>
								<button
									type="submit"
									className={`group relative w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 space-x-2`}
									onClick={handleLogin}
								>
									{isOTPSend ? <span>Verify and Create Account</span> : <span>Send OTP</span>}
									{spinSingUpLoading && <AiOutlineLoading3Quarters className="animate-spin" />}
								</button>
							</div>
						</div>
					</div>
				</form>

				<div className="pt-2">
					<p className="text-center text-sm mt-3">
						Already have an account?{' '}
						<Link to="/shutterpics-login" className="underline text-blue-700 font-semibold">
							Login
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Signup;
