import React, { useContext, useEffect, useState } from 'react';
import Calendar from './Calendar';
import { toast } from 'react-toastify';
import apiList from '../libs/apiList';
import authContext from '../context/auth/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { months } from '../libs/GenerateData';
import { BsBookmarkCheckFill, BsBookmarkStar } from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const Booking = () => {
	const context = useContext(authContext);
	const { isLoggedin, user } = context;

	const navigate = useNavigate(null);
	const { state } = useLocation();

	const allEvents = [
		{ evName: 'Ring Ceremony Pacakage (1 day)', prize: '35,000' },
		{ evName: 'Wedding Photography Package (1 day)', prize: '55,000' },
		{
			evName: 'Birthday / Ring Ceremony / Recception Package (1 day)',
			prize: '20,000'
		},
		{ evName: 'Wedding Photography Package (3 day)', prize: '1,20,000' }
	];

	const [bookedDate, setBookedDate] = useState(null);
	const [open, setOpen] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);
	const [spinSingUpLoading, setSpinSingUpLoading] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [eventName, setEventName] = useState(allEvents[0]);
	const [timeSlot, setTimeSlot] = useState('Morning');

	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'Booking Page - SP';

		if (state) {
			setEventName(allEvents[state.eventIdx]);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (open) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}

		return () => {
			document.body.classList.remove('modal-open');
		};
	}, [open]);

	const handleDetails = () => {
		if (isLoggedin) {
			if (!bookedDate) {
				return toast.warn('Please choose the booking date!');
			}

			setOpen(true);
		} else {
			toast('Please get loggedin first');
			navigate('/shutterpics-login');
		}
	};

	const handleBooking = async () => {
		if (!isClicked) {
			setIsClicked(true);
			setSpinSingUpLoading(true);
			try {
				const response = await fetch(apiList.slotbooking, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						authtoken: localStorage.getItem('authtoken')
					},
					body: JSON.stringify({
						name: user.name,
						contactnum: user.contactnum,
						address: user.address,
						bookingdate: bookedDate,
						timeslot: timeSlot,
						eventname: eventName.evName,
						prize: eventName.prize
					})
				});

				const json = await response.json();
				if (json.success) {
					toast.success('Your date has been booked successfully!');
					toast.success(json.message);
					setOpen(false);
					setOpenMessage(true);
					setIsClicked(false);
					setSpinSingUpLoading(false);
				} else {
					setIsClicked(false);
					setSpinSingUpLoading(false);
					toast.error(json.message);
				}
			} catch (err) {
				setIsClicked(false);
				setSpinSingUpLoading(false);
				toast.error('Internal server error occured');
			}
		}
	};

	const handleClose = () => {
		setOpenMessage(false);
		navigate('/shutterpics-profile');
	};

	const handleCloseBooking = () => {
		if (!isClicked) {
			setOpen(false);
		}
	};

	return (
		<div className="bg-gradient-to-tl from-purple-200 to-blue-200 pb-6 sm:pb-8 lg:pb-12">
			<Helmet>
				<title>
					ShutterPics Booking: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="mx-auto max-w-screen-2xl px-4 md:px-8 text-gray-700 pt-4">
				<div className="flex items-center justify-center flex-col text-center">
					<h1 className="text-3xl lg:text-4xl font-bold mb-2">Book your day with us.</h1>
					<p className="font-semibold text-lg max-md:text-justify lg:w-4/5">
						Whether it's a wedding, corporate event, or a personal photoshoot, our talented team is here to make your vision come to life. Simply choose your date and let us handle the rest. Your perfect session is just a few clicks away!
					</p>
				</div>

				<div className="flex flex-col items-center justify-center">
					<div className="flex items-center justify-evenly w-full flex-wrap">
						<label htmlFor="event" className="m-4">
							<span className="block text-xl font-semibold mb-1">Select Event</span>
							<select
								name="event"
								id="event"
								onChange={(e) => {
									setEventName(e.target.value);
								}}
								value={eventName.evName}
								className="border border-gray-300 w-48 pb-1 rounded-md p-1 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10"
							>
								{allEvents.map((data, idx) => {
									return <option key={idx}>{data.evName}</option>;
								})}
							</select>
						</label>

						<label htmlFor="slot" className="m-4">
							<span className="block text-xl font-semibold mb-1">Select Time Slot</span>
							<select
								name="slot"
								id="slot"
								onChange={(e) => {
									setTimeSlot(e.target.value);
								}}
								value={timeSlot}
								className="border border-gray-300 w-48 pb-1 rounded-md p-1 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10"
							>
								<option>Morning</option>
								<option>Afternoon</option>
								<option>Evening</option>
								<option>Night</option>
							</select>
						</label>
					</div>

					<div className="p-4 text-center">
						<div className="text-xl font-semibold">Check Availability</div>
						{bookedDate ? (
							<div>
								<span className="font-semibold">Available on: </span>
								{bookedDate.date()} {months[bookedDate.month()]}, {bookedDate.year()}
							</div>
						) : (
							<span className="">Select Date from the Calendar</span>
						)}
					</div>

					<div className="p-4">
						<Calendar setBookedDate={setBookedDate} />
					</div>

					<div className="w-full text-center">
						<button
							onClick={handleDetails}
							className="bg-purple-500 hover:bg-purple-600 text-center text-white py-2 px-14 rounded-lg my-8"
						>
							<span>Book Now</span> <BsBookmarkStar className="inline-block" />{' '}
						</button>
					</div>
				</div>

				<section
					onClick={handleCloseBooking}
					className={`fixed inset-0 h-full w-full z-50 flex items-center justify-center transition-colors ease-in-out duration-500 ${open ? 'bg-black/80 pointer-events-auto' : 'invisible pointer-events-none'
						}`}
				>
					<div
						className="px-4"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div
							className={`rounded-lg bg-gray-200 max-w-lg p-4 transition-all ease-in duration-100 ${open ? 'scale-100' : 'scale-50'
								}`}
						>
							<div className="text-center mb-4">
								<h1 className="p-1 text-xl font-semibold">Cross check your Booking Details</h1>
								{bookedDate && (
									<div className="p-1 mx-auto text-start">
										<span className="font-semibold">Booked Date : </span>
										{bookedDate.date()} {months[bookedDate.month()]}, {bookedDate.year()}
									</div>
								)}
								<div className="p-1 mx-auto text-start">
									<span className="font-semibold">Time Slot : </span>
									{timeSlot}
								</div>

								<div className="p-1 mx-auto text-start">
									<span className="font-semibold">Event : </span>
									{eventName.evName}
								</div>

								<div className="p-1 mx-auto text-start">
									<span className="font-semibold">Total Amount : </span>
									{eventName.prize}
								</div>
							</div>

							<div className="text-center py-2 space-x-8 flex justify-around">
								<button
									onClick={handleCloseBooking}
									className="text-center p-2 rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 w-2/5"
								>
									Cancle
								</button>

								<button
									onClick={handleBooking}
									className="text-center p-2 rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 w-2/5 flex items-center justify-center space-x-1"
								>
									<span>Book Now</span>
									{spinSingUpLoading ? (
										<AiOutlineLoading3Quarters className="animate-spin" aria-hidden="true" />
									) : (
										<BsBookmarkStar />
									)}
								</button>
							</div>
						</div>
					</div>
				</section>

				<section
					onClick={handleClose}
					className={`fixed inset-0 h-full w-full z-50 flex items-center justify-center transition-colors ease-in-out duration-500 ${openMessage ? 'bg-black/80 pointer-events-auto' : 'invisible pointer-events-none'
						}`}
				>
					<div
						className="px-4"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div
							className={`rounded-lg bg-gray-200 max-w-lg p-4 transition-all ease-in duration-100 ${openMessage ? 'scale-100' : 'scale-50'
								}`}
						>
							<div className="text-center">
								{bookedDate && (
									<h1 className="p-1 text-xl font-semibold">
										{bookedDate.date()} {months[bookedDate.month()]}, {bookedDate.year()}{' '}
										<span className="block">
											Date has been booked succefully{' '}
											<BsBookmarkCheckFill className="inline-block" />
										</span>
									</h1>
								)}
							</div>

							<div className="text-center">
								<p className="text-justify mb-4">
									Check the profile page for the booking details, Our team will contact you soon for
									the confirmation.
								</p>
								<button
									onClick={handleClose}
									className="text-center p-2 rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 w-2/5"
								>
									Ok
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Booking;
