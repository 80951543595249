import React, { useContext } from 'react';
import { IoLogoInstagram } from 'react-icons/io5';
import { BsTwitterX } from 'react-icons/bs';
import { FaFacebookSquare, FaYoutube } from 'react-icons/fa';
// import { FaWhatsapp } from 'react-icons/fa';
import logo from '../allimages/logo192.png';
import { Link } from 'react-router-dom';
import authContext from '../context/auth/authContext';
import { Helmet } from 'react-helmet';

const Footer = () => {
	const context = useContext(authContext);

	const { isLoggedin } = context;
	return (
		<div className="bg-neutral-600 text-white pt-4 ">
			<Helmet>
				<title>
					ShutterPics : Professional Photography Services | Top-Rated Photoshoots | Videography |
					Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="h-32 p-4">
				<img src={logo} alt="" className="w-24 h-full mx-auto" />
			</div>

			<footer className="mx-auto max-w-screen-2xl px-4 md:px-8">
				<div className="flex flex-col items-center justify-between gap-4 border-t border-b py-6 md:flex-row rounded-md px-3">
					<nav className="flex flex-wrap justify-center gap-x-4 gap-y-2 md:justify-start md:gap-6">
						<Link
							to="/"
							className="transition duration-100 hover:text-purple-500 active:text-purple-600"
						>
							Home
						</Link>
						<Link
							to="/shutterpics-gallery"
							className="transition duration-100 hover:text-purple-500 active:text-purple-600"
						>
							Gallery
						</Link>
						{/* <Link
							to="/shutterpics-gallery"
							className="transition duration-100 hover:text-purple-500 active:text-purple-600"
						>
							Gallery
						</Link> */}
						<Link
							to="/shutterpics-online-services"
							className="transition duration-100 hover:text-purple-500 active:text-purple-600"
						>
							Services
						</Link>
						<Link
							to="/shutterpics-online-booking"
							className="transition duration-100 hover:text-purple-500 active:text-purple-600"
						>
							Booking
						</Link>
						<Link
							to="/shutterpics-contact"
							className="transition duration-100 hover:text-purple-500 active:text-purple-600"
						>
							Contact Us
						</Link>
						{!isLoggedin && (
							<>
								<Link
									to="/shutterpics-login"
									className="transition duration-100 hover:text-purple-500 active:text-purple-600"
								>
									Login
								</Link>
								<Link
									to="/shutterpics-signup"
									className="transition duration-100 hover:text-purple-500 active:text-purple-600"
								>
									Signup
								</Link>
							</>
						)}{' '}
					</nav>

					<div className="flex gap-14">
						<Link
							to="https://www.youtube.com/@shutterpics"
							target="_blank"
							className="text-green-400 transition duration-100 hover:text-green-500 active:text-green-500"
						>
							<FaYoutube className="scale-150" />
						</Link>

						<Link
							to="https://www.instagram.com/shutterpics_wedd/"
							target="_blank"
							className="text-pink-400 transition duration-100 hover:text-pink-500 active:text-pink-500"
						>
							<IoLogoInstagram className="scale-150" />
						</Link>

						<Link
							to="https://x.com/shutterpic_s?t=RWtfW_yE5pnp5o_u5hDzFw&s=09"
							target="_blank"
							className="text-gray-900 transition duration-100 hover:text-black active:text-black"
						>
							<BsTwitterX className="scale-150" />
						</Link>

						<Link
							to="https://www.facebook.com/profile.php?id=100089798807646"
							target="_blank"
							className="text-blue-700 bg-white transition duration-100 hover:text-blue-800 active:text-blue-800"
						>
							<FaFacebookSquare className="scale-150" />
						</Link>
					</div>
				</div>

				<div className="py-8 text-center text-sm">© 2024 - Shutter Pics. All rights reserved.</div>
			</footer>
		</div>
	);
};

export default Footer;
