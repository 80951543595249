import React, { useEffect } from 'react';
import logo from '../allimages/logo192.png';
import shutter from '../allimages/shutter.jpg';
import { Helmet } from 'react-helmet';
const About = () => {
	// Scroll to the top of the page when the component mounts (or loads) after the redirect
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>
					ShutterPics About: Professional Photography Services | Free Photoshoots Demos | Sony
					Camera Videography | DSLR Video Production | Near Me Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services,Near Me, Online Booking, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>

			<section className="flex flex-col items-center justify-center mb-20">
				<div className="text-center flex flex-col items-center">
					<h1 className="text-3xl lg:text-4xl font-bold mb-2">About Us</h1>
					<p className="mb-10 font-semibold md:text-lg max-md:text-justify lg:w-4/5">
						We capture life's most precious moments with creativity and care. Founded by Aniket
						Rajput , a passionate photographer with over 6+ years of experience, our mission is to
						provide exceptional photography services that preserve your memories for a lifetime.
					</p>
				</div>

				<section className="flex items-center justify-center flex-col lg:flex-row lg:mx-12 mb-12 border border-blue-300 rounded-xl max-w-screen-lg py-2 bg-gradient-to-tl from-purple-300 to-blue-300">
					<div className="lg:w-2/6 h-48 py-2 md:py-4">
						<img
							src={logo}
							alt="photography"
							className="w-full h-full object-contain mix-blend-darken"
						/>
					</div>

					<div className="flex items-center justify-center lg:w-4/6 p-2 lg:pr-4">
						<p className="text-justify font-semibold">
							At Shutterpics, we specialize in capturing life's most precious moments with
							creativity and passion. From stunning photoshoots and vibrant birthday celebrations to
							timeless wedding memories and special occasions, our skilled team is dedicated to
							delivering high-quality photography that you'll cherish forever. Let us turn your
							moments into lasting memories with our expert eye and artistic touch.
						</p>
					</div>
				</section>

				<section className="flex items-center justify-center flex-col-reverse lg:flex-row lg:mx-12 mb-12 border border-blue-300 rounded-xl max-w-screen-lg bg-gradient-to-tl from-purple-300 to-blue-300 overflow-hidden">
					<div className="flex items-center justify-center lg:w-4/6 p-2 py-4 lg:pl-4">
						<p className="text-justify font-semibold">
							Our journey began with a simple love for photography. Over the years, this passion has
							grown into a thriving business dedicated to helping clients celebrate and remember
							their most important occasions. From intimate family gatherings to grand weddings and
							corporate events, we bring a unique blend of artistry, professionalism, and personal
							touch to every shoot. We strive to exceed your expectations by delivering high-quality
							images that you will treasure for a lifetime.
						</p>
					</div>

					<div className="lg:w-2/6 w-full h-52">
						<img
							src={shutter}
							alt="photoshoot"
							className="w-full h-full object-cover mix-blend-darken"
						/>
					</div>
				</section>

				<div className="border border-blue-300 rounded-lg bg-gradient-to-tl from-purple-300 to-blue-300 p-2 lg:w-2/5">
					<p className="text-justify lg:text-center">
						Thank you for considering <span className="font-bold">Shutter Pics</span> for your
						photography needs. We look forward to creating beautiful memories with you.
					</p>
				</div>
			</section>
		</>
	);
};

export default About;
