import React, { useEffect, useState } from 'react';
import pic1 from '../allimages/bride shoot.jpg';
import pic2 from '../allimages/bride.jpg';
import pic3 from '../allimages/bride3.jpg';
import pic4 from '../allimages/bride4.jpg';
import pic5 from '../allimages/bride5.jpg';
import pic6 from '../allimages/demo.jpg';
import pic7 from '../allimages/engage.jpg';
import pic8 from '../allimages/groom.jpg';
import pic9 from '../allimages/groom2.jpg';
import pic10 from '../allimages/groombride.jpg';
import pic11 from '../allimages/the bride.jpg';
import pic12 from '../allimages/change1.jpg';
import pic13 from '../allimages/change2.jpg';
import pic14 from '../allimages/change3.jpg';
import pic15 from '../allimages/change4.jpg';
import pic16 from '../allimages/change5.jpg';
import pic17 from '../allimages/change6.jpg';
import pic18 from '../allimages/change7.jpg';
import pic19 from '../allimages/change8.jpg';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

const Gallery = () => {
	const [open, setOpen] = useState(false);
	const [idx, setIdx] = useState(0);

	useEffect(() => {
		if (open) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}

		return () => {
			document.body.classList.remove('modal-open');
		};
	}, [open]);

	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'Gallery - SP';
	}, []);

	const veiwImage = (idx) => {
		setOpen(true);
		setIdx(idx);
	};

	const allImg = [
		{
			imgLink: pic1
		},
		{
			imgLink: pic2
		},
		{
			imgLink: pic3
		},
		{
			imgLink:
				'https://www.haringphotography.com/wp-content/uploads/2019/10/best-miami-wedding-photographers-12-1800x1200.jpg'
		},
		{
			imgLink: pic4
		},
		{
			imgLink: pic5
		},
		{
			imgLink: pic6
		},
		{
			imgLink: pic7
		},
		{
			imgLink: pic8
		},
		{
			imgLink: pic9
		},
		{
			imgLink: pic10
		},
		{
			imgLink: pic11
		},
		{
			imgLink: pic12
		},
		{
			imgLink: pic13
		},
		{
			imgLink: pic14
		},
		{
			imgLink: pic15
		},
		{
			imgLink: pic16
		},
		{
			imgLink: pic17
		},
		{
			imgLink: pic18
		},
		{
			imgLink: pic19
		}
	];

	return (
		<section class="bg-gradient-to-tl from-purple-200 to-blue-200 text-gray-700 body-font">
			<Helmet>
				<title>
					ShutterPics Gallery: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div class="container px-4 mx-auto flex flex-wrap pt-4">
				<div class="flex w-full mb-4 flex-wrap">
					<h1 class="lg:w-1/3 text-3xl lg:text-4xl font-bold text-gray-700 mb-2">Photo Gallery</h1>
					<p class="lg:pl-6 lg:w-2/3 mx-auto lg:font-semibold lg:text-lg">
						We showcase the moments we’ve had the privilege to capture. Our gallery is a collection
						of diverse projects that highlight our passion, creativity, and dedication to preserving
						your most cherished memories.
					</p>
				</div>
				<div class="flex flex-wrap max-md:flex-col md:-m-2 -m-1">
					<div class="flex flex-wrap w-1/2 max-md:w-full">
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[7].imgLink}
								onClick={() => {
									veiwImage(7);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[4].imgLink}
								onClick={() => {
									veiwImage(4);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-full h-52 lg:h-96">
							<img
								alt="gallery"
								class="w-full h-full object-cover object-center block"
								src={allImg[3].imgLink}
								onClick={() => {
									veiwImage(3);
								}}
							/>
						</div>
					</div>
					<div class="flex flex-wrap w-1/2 max-md:w-full">
						<div class="md:p-2 p-1 w-full h-52 lg:h-96">
							<img
								alt="gallery"
								class="w-full h-full object-cover object-center block"
								src={allImg[10].imgLink}
								onClick={() => {
									veiwImage(10);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[2].imgLink}
								onClick={() => {
									veiwImage(2);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[19].imgLink}
								onClick={() => {
									veiwImage(19);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="container px-4 py-6 mx-auto flex flex-wrap">
				<div class="flex flex-wrap max-md:flex-col md:-m-2 -m-1">
					<div class="flex flex-wrap w-1/2 max-md:w-full">
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[6].imgLink}
								onClick={() => {
									veiwImage(6);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[5].imgLink}
								onClick={() => {
									veiwImage(5);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-full h-52 lg:h-96">
							<img
								alt="gallery"
								class="w-full h-full object-cover object-center block"
								src={allImg[8].imgLink}
								onClick={() => {
									veiwImage(8);
								}}
							/>
						</div>
					</div>
					<div class="flex flex-wrap w-1/2 max-md:w-full">
						<div class="md:p-2 p-1 w-full h-52 lg:h-96">
							<img
								alt="gallery"
								class="w-full h-full object-cover object-center block"
								src={allImg[1].imgLink}
								onClick={() => {
									veiwImage(1);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[11].imgLink}
								onClick={() => {
									veiwImage(11);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[9].imgLink}
								onClick={() => {
									veiwImage(9);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="container px-4 pb-4 mx-auto flex flex-wrap">
				<div class="flex flex-wrap max-md:flex-col md:-m-2 -m-1">
					<div class="flex flex-wrap w-1/2 max-md:w-full">
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[12].imgLink}
								onClick={() => {
									veiwImage(12);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[15].imgLink}
								onClick={() => {
									veiwImage(15);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-full h-52 lg:h-96">
							<img
								alt="gallery"
								class="w-full h-full object-cover object-center block"
								src={allImg[16].imgLink}
								onClick={() => {
									veiwImage(16);
								}}
							/>
						</div>
					</div>
					<div class="flex flex-wrap w-1/2 max-md:w-full">
						<div class="md:p-2 p-1 w-full h-52 lg:h-96">
							<img
								alt="gallery"
								class="w-full h-full object-cover object-center block"
								src={allImg[17].imgLink}
								onClick={() => {
									veiwImage(17);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[13].imgLink}
								onClick={() => {
									veiwImage(13);
								}}
							/>
						</div>
						<div class="md:p-2 p-1 w-1/2 h-32 md:h-52">
							<img
								alt="gallery"
								class="w-full object-cover h-full object-center block"
								src={allImg[18].imgLink}
								onClick={() => {
									veiwImage(18);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div
				onClick={() => {
					setOpen(!open);
				}}
				className={`fixed inset-0 h-full w-full z-50 flex items-center justify-center transition-colors ease-in-out duration-300 ${
					open ? 'bg-black/80' : 'invisible'
				}`}
			>
				<div
					className="px-4"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<img
						src={allImg[idx].imgLink}
						alt=""
						className={`rounded-lg object-contain pointer-events-none max-h-[30rem] transition-all ease-in duration-100 ${
							open ? 'scale-100' : 'scale-50'
						}`}
					/>
				</div>
			</div>

			<div className="py-10 px-4">
				<div className="text-center mb-4">
					<h1 className="text-3xl lg:text-4xl font-bold text-gray-700">
						Some Reels and Cinematic Videography
					</h1>
				</div>
				<div className="flex flex-wrap items-center justify-center">
					<div className="rounded-lg h-full w-80 overflow-hidden flex items-center justify-center m-4">
						<ReactPlayer controls url="https://www.youtube.com/shorts/enIhIcWBzDI?feature=share" />
					</div>
					<div className="rounded-lg h-full w-80 overflow-hidden flex items-center justify-center m-4">
						<ReactPlayer
							controls
							url="https://youtube.com/shorts/dhHKwRVmt-E?si=NiV3Qd7uEYAwzUFc"
						/>
					</div>
					<div className="rounded-lg h-full w-80 overflow-hidden flex items-center justify-center m-4">
						<ReactPlayer
							controls
							url="https://youtube.com/shorts/7IvLRphERj8?si=uXD3NIvE-_TomyhX"
						/>
					</div>
					<div className="rounded-lg h-full w-80 overflow-hidden flex items-center justify-center m-4">
						<ReactPlayer
							controls
							url="https://youtube.com/shorts/V9j107zm2lE?si=Qu3s_2qFOEVDyKfn"
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Gallery;
