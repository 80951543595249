import React, { useState } from 'react';
import AuthContext from './authContext';
import apiList from '../../libs/apiList';
import { toast } from 'react-toastify';

const AuthState = (props) => {
	const [isLoggedin, setIsLoggedin] = useState(false);
	const [user, setUser] = useState(false);

	const getUser = async () => {
		try {
			const response = await fetch(apiList.getuser, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					authtoken: localStorage.getItem('authtoken')
				}
			});
			const json = await response.json();

			if (json.success) {
				setUser(json.data);
			}
		} catch (err) {
			toast.warn('Unable to fetch user data.');
		}
	};
	const checkAvailableDate = async () => {
		try {
			const response = await fetch(apiList.getslots, {
				method: 'GET'
			});

			await response.json();
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<AuthContext.Provider
			value={{
				isLoggedin,
				setIsLoggedin,
				user,
				getUser,
				setUser,
				checkAvailableDate
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthState;
