import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { HiMiniBars3CenterLeft } from 'react-icons/hi2';
import { RxCross2 } from 'react-icons/rx';
import { FaCircleUser } from 'react-icons/fa6';
import logo from '../allimages/logo192.png';
import authContext from '../context/auth/authContext';
import { MdHome, MdSwitchAccount } from 'react-icons/md';
import { RiHealthBookFill } from 'react-icons/ri';
import { RiGalleryFill } from 'react-icons/ri';
import { MdContactMail } from 'react-icons/md';
import { MdCamera } from 'react-icons/md';
import { FaAngleRight } from 'react-icons/fa6';
import { BiSolidLogInCircle } from 'react-icons/bi';
import { Helmet } from 'react-helmet';

const Navbar = () => {
	const [open, setOpen] = useState(false);
	const context = useContext(authContext);
	const { user, isLoggedin, setIsLoggedin, getUser, checkAvailableDate } = context;

	useEffect(() => {
		if (open) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}

		return () => {
			document.body.classList.remove('modal-open');
		};
	}, [open]);

	useEffect(() => {
		if (localStorage.getItem('authtoken')) {
			setIsLoggedin(localStorage.getItem('authtoken'));
			getUser();
		}

		checkAvailableDate();
		// eslint-disable-next-line
	}, []);

	const getClose = () => {
		if (open) {
			setOpen(false);
		}
	};

	let navLinks = [
		{ name: 'Home', link: '/', icon: MdHome },
		{ name: 'Booking', link: '/shutterpics-online-booking', icon: RiHealthBookFill },
		{ name: 'Services', link: '/shutterpics-online-services', icon: MdCamera },
		{ name: 'Gallery', link: '/shutterpics-gallery', icon: RiGalleryFill },
		{ name: 'Contact Us', link: '/shutterpics-contact', icon: MdContactMail }
	];

	return (
		<nav className="sticky top-0 left-0 bg-purple-300 z-40 overflow-hidden text-gray-800">
			<Helmet>
				<title>
					ShutterPics Navbar: Professional Photography Services | Top-Rated Photoshoots |
					Videography | Music Video Production | Trusted Photography Studio
				</title>
				<meta
					name="description"
					content="Photography Services, Photoshoots, Wedding Photography, Event Photography, Videography, Music Videos, Portrait Photography, Commercial Photography, Product Photography, Studio Rental"
				/>
			</Helmet>
			<div className="flex items-center justify-between px-4">
				<div className="flex items-center space-x-1">
					<div className="h-[2.7rem] lg:h-16 p-2">
						<img src={logo} alt="" className="w-full h-full object-contain mix-blend-darken" />
					</div>
					<Link to="/" className="pr-2 lg:border-r-2 border-purple-400 font-semibold">
						ShutterPics
					</Link>

					<ul className="hidden lg:flex pl-4 space-x-10 font-semibold">
						{navLinks.map((linkData) => {
							return (
								<Link
									to={linkData.link}
									key={linkData.link}
									className="hover:text-purple-700 hover:scale-105 transition-all duration-200"
								>
									{linkData.name}
								</Link>
							);
						})}
					</ul>
				</div>

				<div className="hidden lg:block space-x-4 text-white">
					{isLoggedin ? (
						<>
							<Link
								to={
									localStorage.getItem('type') === 'admin'
										? '/admin/profile'
										: '/shutterpics-profile'
								}
								className="bg-purple-500 hover:bg-purple-600 py-2 px-3 rounded-full w-32 flex items-center justify-center space-x-2 text-center"
							>
								<span>Profile</span> <FaCircleUser />
							</Link>
						</>
					) : (
						<>
							<Link
								to="/shutterpics-login"
								className="bg-purple-500 hover:bg-purple-600 py-2 px-3 rounded-lg w-32 inline-block text-center"
							>
								Login
							</Link>
							<Link
								to="/shutterpics-signup"
								className="bg-purple-500 hover:bg-purple-600 py-2 px-3 rounded-lg w-32 inline-block text-center"
							>
								SignUp Now
							</Link>
						</>
					)}
				</div>

				<div className="lg:hidden flex items-center justify-center space-x-8 px-2">
					{isLoggedin ? (
						<Link
							to={
								localStorage.getItem('type') === 'admin' ? '/admin/profile' : '/shutterpics-profile'
							}
						>
							<FaUser className="rounded-full border border-black p-0.5 scale-150" />
						</Link>
					) : (
						<Link to="/shutterpics-login">
							<FaUser className="rounded-full border border-black p-0.5 scale-150" />
						</Link>
					)}

					{open ? (
						<RxCross2
							onClick={() => {
								setOpen(!open);
							}}
							className="scale-150"
						/>
					) : (
						<HiMiniBars3CenterLeft
							onClick={() => {
								setOpen(!open);
							}}
							className="scale-150"
						/>
					)}
				</div>
			</div>

			<div
				className={`fixed top-0 left-0 w-full h-full transition-all duration-500 ease-in-out ${
					open ? 'bg-black/40 pointer-events-auto' : 'bg-transparent pointer-events-none'
				}`}
			>
				<div
					className={`fixed top-0 right-0 w-full h-full transform ${
						open ? '-translate-x-0' : '-translate-x-full'
					} transition-transform duration-200 ease-in-out flex text-gray-700`}
				>
					<div className="bg-gradient-to-tl from-purple-100 to-blue-100 w-[80%] overflow-y-auto nobar">
						<Link
							to={
								localStorage.getItem('type') === 'admin' ? '/admin/profile' : '/shutterpics-profile'
							}
							onClick={getClose}
							className="flex items-center justify-start bg-purple-300 z-20 p-6 sticky top-0 left-0"
						>
							<div className="flex items-center space-x-8 pl-2">
								<FaCircleUser className="scale-[3]" />
								<h1 className="text-2xl font-semibold">
									{isLoggedin
										? localStorage.getItem('type') === 'admin'
											? 'Admin'
											: user.name
										: 'Guest User'}
								</h1>
							</div>
						</Link>

						<div className="">
							<ul onClick={getClose} className="flex flex-col font-medium">
								{navLinks.map((linkData) => {
									return (
										<Link
											to={linkData.link}
											key={linkData.link}
											className="p-4 border-b border-gray-300 flex items-center justify-between"
										>
											<div className="flex items-center space-x-2">
												<linkData.icon size={20} />
												<span>{linkData.name}</span>
											</div>
											<FaAngleRight className="place-items-end text-gray-400" />
										</Link>
									);
								})}

								{!isLoggedin && (
									<>
										<Link
											to="/shutterpics-login"
											className="flex items-center justify-between p-4 border-b border-gray-300"
										>
											<div className="flex items-center space-x-2">
												<BiSolidLogInCircle size={20} />
												<span>Login</span>
											</div>
											<FaAngleRight className="place-items-end text-gray-400" />
										</Link>
										<Link
											to="/shutterpics-signup"
											className="flex items-center justify-between p-4 border-b border-gray-300"
										>
											<div className="flex items-center space-x-2">
												<MdSwitchAccount size={20} />
												<span>SignUp Now</span>
											</div>
											<FaAngleRight className="place-items-end text-gray-400" />
										</Link>
									</>
								)}
							</ul>
						</div>
					</div>

					<div className="h-full w-[20%]" onClick={getClose}></div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
